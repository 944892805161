import React from 'react';
import { Layout } from '../components/shared/Layout';

const CookiePolicyPage = (): JSX.Element => {
  return (
    <Layout>
      <main className="">
        <section className="bg-white py-8">
          <h1>Page not founc</h1>
          <p>The page you requested doe not exist</p>
        </section>
      </main>
    </Layout>
  );
};

export default CookiePolicyPage;
